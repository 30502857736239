var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                {
                  staticClass: "mt-4",
                  attrs: { header: _vm.$t("Outlet selection") }
                },
                [
                  _vm.selected != null && _vm.blocked != null
                    ? _c(
                        "b-table-simple",
                        { staticClass: "border-0", attrs: { bordered: "" } },
                        [
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.outletCategories, function(
                                outlets,
                                catName,
                                cat_index
                              ) {
                                return [
                                  cat_index > 0
                                    ? _c(
                                        "tr",
                                        {
                                          key: "spacer_" + catName,
                                          staticStyle: { border: "none" }
                                        },
                                        [
                                          _c("td", {
                                            staticStyle: { border: "none" }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  outlets.length > 0
                                    ? _c("tr", { key: "cat_" + catName }, [
                                        _c(
                                          "td",
                                          { attrs: { colspan: "2" } },
                                          [
                                            _c("page-snippet", {
                                              attrs: { name: catName }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-bottom" },
                                          [
                                            outlets.length > 1 &&
                                            catName !==
                                              "rights_management_services"
                                              ? _c(
                                                  "b-button-group",
                                                  {},
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "text-nowrap",
                                                        attrs: {
                                                          variant: "success"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.categorySetAll(
                                                              catName,
                                                              true
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Select all")
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "text-nowrap",
                                                        attrs: {
                                                          variant: "danger"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.categorySetAll(
                                                              catName,
                                                              false
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Deselect all"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._l(outlets, function(outlet) {
                                    return [
                                      _c(
                                        "tr",
                                        { key: "ol_" + outlet.JsonValue },
                                        [
                                          _c("td", [
                                            outlet.ImageUrl
                                              ? _c("img", {
                                                  class: {
                                                    "portal-logo": true,
                                                    disabled: !_vm.selected.includes(
                                                      outlet.JsonValue
                                                    )
                                                  },
                                                  attrs: {
                                                    src: outlet.ImageUrl,
                                                    alt: outlet.Value
                                                  }
                                                })
                                              : _vm._e()
                                          ]),
                                          _c("td", [
                                            _c("h3", [
                                              _vm._v(_vm._s(outlet.Value))
                                            ]),
                                            _c("p", [
                                              _vm._v(_vm._s(outlet.Description))
                                            ])
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              !_vm.blocked.includes(
                                                outlet.JsonValue
                                              )
                                                ? _c(
                                                    "b-button-group",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "text-nowrap",
                                                          attrs: {
                                                            variant: _vm.selected.includes(
                                                              outlet.JsonValue
                                                            )
                                                              ? "success"
                                                              : "secondary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.toggle(
                                                                outlet.JsonValue
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "Available"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "text-nowrap",
                                                          attrs: {
                                                            variant: !_vm.selected.includes(
                                                              outlet.JsonValue
                                                            )
                                                              ? "danger"
                                                              : "secondary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.toggle(
                                                                outlet.JsonValue
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "Not Available"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.blocked.includes(
                                                outlet.JsonValue
                                              )
                                                ? _c("page-snippet", {
                                                    attrs: {
                                                      name: "outlet_blocked"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      outlet.JsonValue == "Beatport" &&
                                      _vm.selected.includes("Beatport")
                                        ? _c(
                                            "tr",
                                            {
                                              key:
                                                "ol_beatport" + outlet.JsonValue
                                            },
                                            [
                                              _c("td"),
                                              _c(
                                                "td",
                                                { attrs: { colspan: "2" } },
                                                [
                                                  _c("page-snippet", {
                                                    attrs: {
                                                      name: "beatport-label"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      outlet.JsonValue == "YouTube_ContentID" &&
                                      _vm.selected.includes("YouTube_ContentID")
                                        ? _c(
                                            "tr",
                                            {
                                              key:
                                                "ol_youtube" + outlet.JsonValue
                                            },
                                            [
                                              _c("td"),
                                              _c(
                                                "td",
                                                { attrs: { colspan: "2" } },
                                                [
                                                  _c("field", {
                                                    attrs: {
                                                      defs: _vm.defs,
                                                      name:
                                                        "Attributes.YT_MATCH_POLICY",
                                                      model: _vm.release
                                                    }
                                                  }),
                                                  _c("page-snippet", {
                                                    attrs: {
                                                      name: "YouTube_ContentID"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      outlet.JsonValue == "FacebookAll" &&
                                      _vm.selected.includes("FacebookAll")
                                        ? _c(
                                            "tr",
                                            {
                                              key:
                                                "ol_facebook" + outlet.JsonValue
                                            },
                                            [
                                              _c("td"),
                                              _c(
                                                "td",
                                                { attrs: { colspan: "2" } },
                                                [
                                                  _c("field", {
                                                    attrs: {
                                                      defs: _vm.defs,
                                                      name:
                                                        "Attributes.RightsClaimPolicyType",
                                                      model: _vm.release
                                                    }
                                                  }),
                                                  _c("page-snippet", {
                                                    attrs: { name: "facebook" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.selected.length == 0
                ? _c(
                    "b-alert",
                    {
                      staticClass: "mt-4",
                      attrs: { show: "", variant: "danger" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Select at least one outlet to proceed")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { disabled: _vm.selected.length == 0 },
                  on: {
                    click: function($event) {
                      return _vm.$emit("proceed")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Proceed with next step")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }